<template>
  <div class='reset-password'>
    <div class='mzy'>Reset Password</div>
    <div class='ins'>To reset your password, please provide your working email address.</div>
    <div class='tvt'>
      <input-element
        type='email'
        label='Working Email Address'
        v-model='inputs.email'/>
    </div>
    <div class='owf'>
      <button-element
        :disabled='sending'
        @click='send'>
        Send
        <progress-element class='llo'
          :active='sending'/>
      </button-element>
    </div>
    <flash-message-component
      ref='successDialog'
      type='success'>
      A reset password email has been sent to <span class='thw'>{{ inputs.email }}</span>.
    </flash-message-component>
    <flash-message-component
      ref='errorDialog'
      type='error'>
      <template v-if='errorCode === "user-not-found"'>Couldn't find your account with that email.</template>
      <template v-if='errorCode === "unknown"'>Oops! Something went wrong. Please try again later.</template>
    </flash-message-component>
  </div>
</template>

<script>
  import { ref } from 'vue'
  import { useStore } from 'vuex'
  import FlashMessage from '@/components/flash-message/flash-message.vue'
  import { SEND_PASSWORD_RESET_EMAIL } from '@/store/action-types'

  export default {
    components: {
      'flash-message-component': FlashMessage
    },

    setup () {
      let store = useStore()

      let sending = ref(false)
      let successDialog = ref(null)
      let errorDialog = ref(null)
      let errorCode = ref('')
      let inputs = ref({ email: '' })

      let send = async () => {
        let email = inputs.value.email
        if (!email) return

        try {
          sending.value = true
          await store.dispatch(SEND_PASSWORD_RESET_EMAIL, { email })
          successDialog.value.show()
        } catch (error) {
          switch (error.code) {
            case 'auth/invalid-email':
            case 'auth/user-not-found':
              errorCode.value = 'user-not-found'
              break
            default:
              errorCode.value = 'unknown'
          }
          errorDialog.value.show()
        } finally {
          sending.value = false
        }
      }

      return {
        sending,
        successDialog,
        errorDialog,
        errorCode,
        inputs,
        send
      }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .mzy {
    margin-top: 16px;
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'lg');
  }

  .ins {
    margin-top: 24px;
    color: map.get(variables.$colors, 'gray', '700');
    font-size: map.get(variables.$font-sizes, 'sm');
  }

  .tvt {
    margin-top: 16px;
    padding: 24px;
    background-color: white;
    border-radius: 4px;
  }

  .owf {
    margin-top: 24px;
    text-align: center;
  }

  .llo {
    margin-left: 16px;
  }

  .thw {
    font-weight: map.get(variables.$font-weights, 'bold');
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .mzy {
      text-align: center;
    }

    .ins {
      margin-top: 32px;
    }

    .tvt {
      padding: 32px;
    }

    .owf {
      margin-top: 32px;
    }
  }
</style>
