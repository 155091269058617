<template>
  <div class='flash-message'>
    <dialog-element ref='dialog'>
      <div class='zfx'>
        <template v-if='type === "success"'>
          <div class='asd'>Success</div>
          <div class='lwv'>
            <slot/>
          </div>
          <div class='ooh'>
            <button-element @click='dialog.hide()'>Close</button-element>
          </div>
        </template>
        <template v-if='type === "error"'>
          <div class='rsd'>Error</div>
          <div class='rwv'>
            <slot/>
          </div>
          <div class='ooh'>
            <button-element
              color='red'
              @click='dialog.hide()'>
              Close
            </button-element>
          </div>
        </template>
      </div>
    </dialog-element>
  </div>
</template>

<script>
  import { ref } from 'vue'

  export default {
    props: {
      type: String
    },

    setup () {
      let dialog = ref(null)

      let show = () => {
        dialog.value.show()
      }

      let hide = () => {
        dialog.value.hide()
      }

      return {
        dialog,
        show,
        hide
      }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .zfx {
    padding: 24px;
  }

  .asd {
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'lg');
  }

  .lwv {
    margin-top: 24px;
  }

  .ooh {
    margin-top: 24px;
    text-align: right;
  }

  .rsd {
    color: map.get(variables.$colors, 'red', '500');
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'lg');
  }

  .rwv {
    margin-top: 24px;
    color: map.get(variables.$colors, 'red', '500');
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .zfx {
      padding: 32px;
    }

    .lwv {
      margin-top: 32px;
    }

    .rwv {
      margin-top: 32px;
    }

    .ooh {
      margin-top: 32px;
    }
  }
</style>
